<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>New Student</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <SelectField
                :value="classroomId"
                :field="selectField"
                @input="classroomId = $event"
              ></SelectField>
            </div>
            <div class="text-center pt-3">
              <SelectField
                :value="studentId"
                :field="selectStudentField"
                @input="studentId = $event"
              ></SelectField>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="api.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import SelectField from "@/components/Form/SelectField.vue";
export default {
  components: {
    SelectField
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    step: 1,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    classroomId: null,
    studentId: null,
    name: null,
    students:[],
     //BOC:[api]
     api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectField: {
      defaultValue: null,
      name: "Classroom",
      options: [],
      optionText: "name",
      optionValue: "id",
    },
    selectStudentField: {
      defaultValue: null,
      name: "Student",
      options: [],
      optionText: "name",
      optionValue: "id",
    },
    valid: true,
     //BOC:[api]
     classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
     //BOC:[api]
     studentApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    classroomId: function () {
      this.getStudents();
    },
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Sponsorship",
        to: {
          name: "PageEducatorInstitutionSponsorship",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Students",
        to: { name: "PageEducatorInstitutionSponsorshipStudent" },
        exact: true,
      },
      {
        text: "New Student",
        to: { name: "PageEducatorInstitutionSponsorshipStudentAdd" },
        exact: true,
      },
    ];
    //EOC
    this.classroomApi.method = "GET";
    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
       "/educator/institution/"+this.$route.params.parentId+"/classroom";

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };

    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      this.selectField.options = resp;
      if(resp[0]){
        this.classroomId = resp[0].id
      }
    }

    this.studentApi.method = "GET";

    this.studentApi.callbackReset = () => {
      this.studentApi.isLoading = true;
      this.studentApi.isError = false;
    };

    this.studentApi.callbackError = (e) => {
      this.studentApi.isLoading = false;
      this.studentApi.isError = true;
      this.studentApi.error = e;
    };

    this.studentApi.callbackSuccess = (resp) => {
      this.studentApi.isLoading = false;
      this.selectStudentField.options = resp;
      if(resp[0]){
        this.studentId = resp[0].id
      }
    }


    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/sponsorship/" +
      this.$route.params.id +
      "/student/add";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: 'PageEducatorInstitutionSponsorshipStudent', params: { parentId:  this.$route.params.parentId,id:  this.$route.params.id } });
    };
  },
  mounted() {
    this.$api.fetch(this.classroomApi);
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: 'Classrooms',
    //     to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId}},
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //   text: "Students",
    //   to: { name: "PageEducatorInstitutionClassroomStudent" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId} },
    //   exact: true,
    // });
    // this.breadcrumbs.push({
    //   text: "New Student",
    //   to: { name: "PageEducatorInstitutionClassroomStudentAdd" },
    //   exact: true,
    // });
    // },
    close() {
      this.$router.push({ name: 'PageEducatorInstitutionSponsorshipStudent', params: { parentId:  this.$route.params.parentId,id:  this.$route.params.id } });
    },
    submit() {
      this.api.params = this.$_.clone({studentId:this.studentId});
      this.$api.fetch(this.api);
    },
    back() {
      this.step--;
    },
    getStudents() {
    this.studentApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
       "/educator/institution/"+this.$route.params.parentId+"/classroom/"+this.classroomId+"/student";
    
       this.$api.fetch(this.studentApi);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
.button-content {
  width: 300px !important;
}
</style>